import React, {useContext, useEffect, useState} from 'react';
import { Helmet } from 'rnd-helmet';
import { injectIntl } from "react-intl";
import SubMenu from "@Components/SubMenu";
import axios from "axios";
import Loading from "@Components/Loading";
import Step from "@Components/Step";
import FormContext from "formContext";

function TrackingHistory({intl, match}) {

  const { locale } = intl;
  const { additionalData, setAdditionalData } = useContext(FormContext);
  const [userApplications, setUserApplications] = useState([]);
  const [trackingHistoryError, setTrackingHistoryError] = useState(false);
  const [steps, setSteps] = useState({});
  const { flow } = steps;
  const { jobApplications, openApplications } = userApplications;
  let trackedJobTitle = '';

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_PREFIX}/application-status?applicationId=${match.params.applicationId}`)
      .then(response => {
        if (response.data.redirect === true && response.data.location) {
          window.location.href = response.data.location;
        }
        else {
          setAdditionalData(prevState => ({
            ...prevState,
            ...response.data
          }));
        }
      if (typeof response.data.applications === 'string') {
        setTrackingHistoryError(true);
      }
      else {
        setTrackingHistoryError(false);
        setSteps(response.data.applications);
      }
    }).catch( error => {
      console.log(error)
    });
  }, []);

  useEffect(() => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_API_PREFIX}/user?currentLanguage=${locale}`),
      axios.get(`${process.env.REACT_APP_API_PREFIX}/user/job-applications`),
      axios.get(`${process.env.REACT_APP_API_PREFIX}/user/open-applications`)
    ]).then(async ([user, jobApplications, openApplications]) => {
      let data = {
        user: user.data,
        jobApplications: jobApplications?.data,
        openApplications:  openApplications?.data,
      }

      setUserApplications(data);
    });
  }, []);

  if ((!flow || (!jobApplications && !openApplications)) && !trackingHistoryError) {
    return <Loading/>;
  }

  if (jobApplications && openApplications) {
    const mergedApplications = [...jobApplications.applications, ...(openApplications.applications ? openApplications.applications : []) ];

    mergedApplications.filter((application) => {
      if (
        application.details &&
        application.details.applicationId &&
        application.details.applicationId === match.params.applicationId
      ) {
        trackedJobTitle = application.title;
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({id: 'Tab.TrackingHistory'})} |{" "}
          {intl.formatMessage({ id: "Breadcrumb.MyApplications" })}
        </title>
        <header className="header bg-variant-white header--s header--welcome"/>
      </Helmet>
      <div className="block--xs">
        <SubMenu skipBreadcrumbs={true} />
        <div className="block__wrapper block__wrapper--stacked wrapper">
          <div className="my-environment-container">
            <h2 className="text-brand-secondary title--l hidden--until-l">
              {intl.formatMessage({id: 'Application.Status.Title'}, { role: trackedJobTitle })}
            </h2>
            <h2 className="text-brand-secondary title--m hidden--from-l">
              {intl.formatMessage({id: 'Application.Status.Title'}, { role: trackedJobTitle })}
            </h2>

            <h3 className="text-brand-secondary title--m mt-l hidden--until-l">
              {intl.formatMessage({id: 'Tab.TrackingHistory'})}
            </h3>
            <h3 className="text-brand-secondary title--s mt-l hidden--from-l">
              {intl.formatMessage({id: 'Tab.TrackingHistory'})}
            </h3>
            {flow && <Step stepFlow = { flow } intl={intl}/>}
            {trackingHistoryError && <div>{intl.formatMessage({ id: "TrackingHistory.Error" })}</div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(TrackingHistory);
